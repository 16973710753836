import { CircularProgress, TextField, withStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDebounce } from "../hooks/common.hooks";
import { useSearchAddressMutation } from "../hooks/property.hooks";
import { primaryColor } from "../scss/colors.scss";
import { checkIsDemoClient } from "../utils";
import { titleCase } from "../utils/text-format";
import CustomButton from "./custom-button";

const CssTextField = withStyles({
  root: {
    flex: "1 1",
    display: "flex",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
        padding: "14px",
      },
      "&:hover fieldset": {
        borderColor: "#E9EDF2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E9EDF2",
      },
      "& input": {
        height: 16,
        fontSize: 14,
        fontFamily: "ProximaNovaRegular",
        paddingRight: 24,
        paddingTop: 19,
        paddingBottom: 19,
        paddingLeft: 14,
      },
    },
  },
})(TextField);

const IS_PASTE_VALUE = true;

const renderSuggestion = (addressList = [], handleSelectItem = () => {}) => {
  return addressList.map((suggestion) => {
    const [id = "", address = ""] = suggestion;
    return (
      <div
        key={id}
        role="button"
        tabIndex="0"
        onKeyDown={() => {}}
        onClick={() => {
          handleSelectItem(suggestion);
        }}
      >
        <li className="address-search-item">{address}</li>
      </div>
    );
  });
};

const AddPropertyInput = ({
  isLoading = false,
  handleUpdateParams = () => {},
  handleAddProperty = () => {},
}) => {
  const [values, setValues] = useState({
    address: "",
    id: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTime, setDebounceTime] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);
  const [result, setResult] = useState({});
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);

  const textInput = useRef("");

  const { enqueueSnackbar } = useSnackbar();
  const searchProperty = useSearchAddressMutation();

  const addressList = useMemo(() => {
    const { addresses = [] } = result;
    return addresses;
  }, [result]);
  const matchedAddress = addressList.find((addressItem) => {
    const [, addressName = ""] = addressItem;
    return addressName.toLowerCase() === textInput.current.value.toLowerCase();
  });

  const handleOnChange = (text, isPasteValue = false) => {
    if (isPasteValue) {
      if (debounceTime === 700) {
        setDebounceTime(0);
      }
    } else if (debounceTime === 0) {
      setDebounceTime(700);
    }
    setSearchTerm(text);
    setIsLoadingAddress(true);
    setResult({});
  };

  const handleSelect = (description) => {
    const [id = "", address = ""] = description;

    setValues((prevValues) => ({
      ...prevValues,
      address,
      id,
    }));
    handleUpdateParams({ address, propertyId: id });
    setResult({});
  };

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        searchProperty.mutate(
          { params: { addresses: debouncedSearchTerm } },
          {
            onSuccess: (searchResult) => {
              setResult(searchResult);
            },
            onError: (error) => {
              const { data: { message = "" } = {} } = error;
              if (!checkIsDemoClient()) {
                enqueueSnackbar(message, { variant: "error" });
              }
            },
          }
        );
      } else setResult({});
      setIsLoadingAddress(false);
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (matchedAddress) {
      handleSelect(matchedAddress);
    }
  }, [matchedAddress]);

  return (
    <div className="add-property-input__container">
      <div className="add-property-input__input justify-content-center position-relative">
        <CssTextField
          autoComplete="off"
          inputRef={textInput}
          placeholder="101, Lucky Street, Australia"
          name="address"
          onChange={(e) => {
            handleOnChange(e.target.value);
            setValues({ ...values, address: e.target.value, id: "" });
          }}
          onPaste={(e) => {
            setValues({ ...values, address: e.target.value, id: "" });
            handleOnChange(e.target.value, IS_PASTE_VALUE);
          }}
          value={titleCase(values.address)}
          variant="outlined"
          id="custom-css-outlined-input"
          InputProps={{
            endAdornment: (
              <div className="address-search-loading-indicator">
                {isLoadingAddress && (
                  <CircularProgress className="connecting-spinner" />
                )}
              </div>
            ),
          }}
        />
        <CustomButton
          onClick={handleAddProperty}
          disabled={!values?.id}
          isLoading={isLoading}
          label="Add"
        />
      </div>
      {addressList.length > 0 && (
        <ul className="address-search-box m-auto">
          {!matchedAddress && renderSuggestion(addressList, handleSelect)}
        </ul>
      )}
    </div>
  );
};

AddPropertyInput.propTypes = {
  handleAddProperty: PropTypes.func,
  handleUpdateParams: PropTypes.func,
};

export default AddPropertyInput;
