import { TextField } from "@material-ui/core";
import isFinite from "lodash/isFinite";
import parseInt from "lodash/parseInt";
import React from "react";
import { ArrowDown, ArrowUp } from "../../images";

const PropertyDetailItem = ({
  icon,
  title,
  type,
  handleUpdateParams,
  params,
  min,
  max,
}) => {
  const handleChangeValue = (e) => {
    const value = parseInt(e.target.value);
    handleUpdateParams({
      [type]: value,
    });
  };

  const handleBlur = (e) => {
    if (e.target.value < min) {
      handleUpdateParams({
        [type]: min,
      });
    } else if (!!max && e.target.value > max) {
      handleUpdateParams({
        [type]: max,
      });
    }
  };
  const handleModifyValue = (modifyType) => {
    const value = parseInt(params[type]);
    if (!params[type]) {
      handleUpdateParams({ [type]: 0 });
    }

    if (modifyType === "increase" && value < max) {
      handleUpdateParams({ [type]: value + 1 });
    }
    if (modifyType === "decrease" && value > min) {
      handleUpdateParams({ [type]: value - 1 });
    }
  };

  return (
    <div className="advanced-option-property-details-item">
      <div className="advanced-option-property-details-item__container">
        <div className="advanced-option-property-details-item__icon">
          {icon}
        </div>
        <div className="advanced-option-property-details-item__input-container">
          <TextField
            label=""
            value={params[type]}
            type="number"
            error={
              !(params[type] || isFinite(params[type])) ||
              (type === "landarea" && params[type] <= 50)
            }
            className={`advanced-option-property-details-item__input ${type}`}
            onChange={handleChangeValue}
            onBlur={handleBlur}
          />
        </div>
        {type !== "landarea" ? (
          <div className="advanced-option-property-details-item__button-group">
            <div
              className="advanced-option-property-details-item__button"
              onClick={() => handleModifyValue("increase")}
            >
              <img src={ArrowUp} alt="arrow-up-icon" />
            </div>
            <div
              className="advanced-option-property-details-item__button"
              onClick={() => handleModifyValue("decrease")}
            >
              <img src={ArrowDown} alt="arrow-up-icon" />
            </div>
          </div>
        ) : (
          <span>
            M<sup>2</sup>
          </span>
        )}
      </div>

      <span>{title}</span>
    </div>
  );
};

PropertyDetailItem.propTypes = {};

export default PropertyDetailItem;
