import { Button } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const CustomSelectButton = ({ label, isSelected, handleUpdateParams }) => {
  return (
    <Button
      className={classNames("advanced-option-property-type__button", {
        "advanced-option-property-type__button--selected": isSelected,
      })}
      onClick={handleUpdateParams}
    >
      {label}
    </Button>
  );
};

CustomSelectButton.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  handleUpdateParams: PropTypes.func,
};

export default CustomSelectButton;
