import { Dialog } from "@material-ui/core";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { PROPERTY_PURPOSE } from "../../../commons/enum";
import AddPropertyInput from "../../../components/add-property-input";
import UpdateProperty from "../../../components/PropertyModal/update-property";
import { useAddPropertyMutation } from "../../../hooks/property.hooks";

const AddPropertyComponent = ({ accountId = "" }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [params, setParams] = useState({
    purpose: PROPERTY_PURPOSE.INVEST,
    accountIds: "",
  });

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, accountIds: accountId }));
  }, [accountId]);
  const [
    isProvidePropertyInformation,
    setIsProvidePropertyInformation,
  ] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMissingData, setIsMissingData] = useState(false);
  const addProperty = useAddPropertyMutation();

  const handleUpdateParams = (value) => {
    setParams((prevParams) => ({ ...prevParams, ...value }));
  };

  const handleAddProperty = () => {
    addProperty.mutate(params, {
      onSuccess: () => {
        enqueueSnackbar("The property has been added", { variant: "success" });
      },
      onError: (error) => {
        const {
          data: { message = "", statusCode, propertyDetail } = {},
        } = error;

        if (statusCode === 400 && message !== "Property is already existed") {
          const {
            bathrooms = 0,
            bedrooms = 0,
            carparks = 0,
            landarea = 0,
            propertyType,
          } = propertyDetail;
          setParams((prevParam) => {
            return {
              ...prevParam,
              bathrooms,
              bedrooms,
              carparks,
              landarea,
              propertyType,
            };
          });
          setIsProvidePropertyInformation(true);
          setIsMissingData(true);
          setOpen(true);
        }
        enqueueSnackbar(
          buildErrorMessage(
            message === "Property is already existed"
              ? message
              : "Missing required fields."
          ),
          { variant: "error" }
        );
      },
    });
  };

  const handleClose = () => {
    setParams({
      accountIds: accountId,
      purpose: PROPERTY_PURPOSE.INVEST,
    });
    setIsProvidePropertyInformation(false);
    setOpen(false);
  };

  return (
    <>
      {isProvidePropertyInformation ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="property-modal__container">
            <UpdateProperty
              isMissingData={isMissingData}
              isLoading={addProperty.isLoading}
              params={params}
              handleUpdateParams={handleUpdateParams}
              handleUpdateProperty={handleAddProperty}
            />
          </div>
        </Dialog>
      ) : (
        <AddPropertyInput
          isLoading={addProperty.isLoading}
          handleUpdateParams={handleUpdateParams}
          handleAddProperty={handleAddProperty}
        />
      )}
    </>
  );
};

AddPropertyComponent.propTypes = {
  accountId: PropTypes.string,
};

export default AddPropertyComponent;
