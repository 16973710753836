import { Tooltip } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { useMemo } from "react";
import { PROPERTY_PURPOSE, PROPERTY_TYPE } from "../../commons/enum";
import { BathRoom, BedRoom, Car, LandArea } from "../../svgs";
import CustomButton from "../custom-button";
import CustomSelectButton from "./custom-select-button";
import PropertyDetailItem from "./property-detail-item";

const longTooltip = (
  <div className="tooltip-content">
    Bedrooms value must between 0 and 9<br />
    Bathrooms value must between 0 and 9<br />
    Garage value must between 0 and 9<br />
    Land size must larger than 50
  </div>
);

const UpdateProperty = ({
  isMissingData = false,
  handleUpdateParams,
  handleUpdateProperty,
  params,
  isLoading = false,
}) => {
  const updatePropertyMessage = useMemo(() => {
    return isMissingData ? (
      <span>
        Looks like we are missing some data. Please add below.{" "}
        <ErrorIcon fontSize="small" />
      </span>
    ) : (
      <span>Change the information to better match your property</span>
    );
  }, [isMissingData]);
  const isMissingPropertyParams = useMemo(() => {
    return (
      !(params.bathrooms || params.bathrooms === 0) ||
      !(params.bedrooms || params.bedrooms === 0) ||
      !(params.carparks || params.carparks === 0) ||
      !(params.landarea && params.landarea > 50) ||
      (params.propertyType !== PROPERTY_TYPE.HOUSE &&
        params.propertyType !== PROPERTY_TYPE.UNIT)
    );
  }, [params]);
  return (
    <div className="property-advanced-options">
      <div className="property-advanced-options__item">
        {!isMissingData && (
          <div className="add-property-usage">
            <span className="add-property-usage__title">Property Usage</span>
            <div
              className="add-property-usage__button-group"
              variant="outlined"
              aria-label="outlined button group"
            >
              <CustomSelectButton
                label="Investor"
                isSelected={params.purpose === PROPERTY_PURPOSE.INVEST}
                handleUpdateParams={() =>
                  handleUpdateParams({
                    purpose: PROPERTY_PURPOSE.INVEST,
                  })
                }
              />
              <CustomSelectButton
                label="Owner Occupier"
                isSelected={params.purpose === PROPERTY_PURPOSE.OCCUPY}
                handleUpdateParams={() =>
                  handleUpdateParams({
                    purpose: PROPERTY_PURPOSE.OCCUPY,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="property-advanced-options__title">
          {updatePropertyMessage}
        </div>
        {params?.address && (
          <div className="advanced-option-property-address">
            {params.address}
          </div>
        )}
        <div className="advanced-option-property-type">
          <span className="advanced-option-property-type__title">
            Property Type
          </span>
          <div
            className="advanced-option-property-type__button-group"
            variant="outlined"
            aria-label="outlined button group"
          >
            <CustomSelectButton
              label="House"
              isSelected={params.propertyType === PROPERTY_TYPE.HOUSE}
              handleUpdateParams={() =>
                handleUpdateParams({
                  propertyType: PROPERTY_TYPE.HOUSE,
                })
              }
            />
            <CustomSelectButton
              label="Unit"
              isSelected={params.propertyType === PROPERTY_TYPE.UNIT}
              handleUpdateParams={() =>
                handleUpdateParams({
                  propertyType: PROPERTY_TYPE.UNIT,
                })
              }
            />
          </div>
        </div>
        <div className="advanced-option-property-details">
          <div className="advanced-option-property-details__title">
            Property details{" "}
            <Tooltip
              title={longTooltip}
              placement="right"
              className="advanced-option-property-details__tooltip"
            >
              <sup>
                <InfoOutlinedIcon />
              </sup>
            </Tooltip>
          </div>
          <div className="advanced-option-property-details__container">
            <PropertyDetailItem
              icon={<BedRoom />}
              title="Bedrooms"
              type="bedrooms"
              handleUpdateParams={handleUpdateParams}
              params={params}
              min={0}
              max={9}
            />
            <PropertyDetailItem
              icon={<BathRoom />}
              title="Bathrooms"
              type="bathrooms"
              handleUpdateParams={handleUpdateParams}
              params={params}
              min={0}
              max={9}
            />
            <PropertyDetailItem
              icon={<Car />}
              title="Garage"
              type="carparks"
              handleUpdateParams={handleUpdateParams}
              params={params}
              min={0}
              max={9}
            />
            <PropertyDetailItem
              icon={<LandArea />}
              title="Land size"
              type="landarea"
              handleUpdateParams={handleUpdateParams}
              params={params}
              min={0}
            />
          </div>
        </div>
      </div>

      <div className="property-modal__button-group">
        <CustomButton
          label="Save Detail"
          onClick={handleUpdateProperty}
          isLoading={isLoading}
          disabled={isLoading || isMissingPropertyParams}
        />
      </div>
    </div>
  );
};

UpdateProperty.propTypes = {};

export default UpdateProperty;
